<template>
  <div
    v-if="detail&&detail.onlineRefund"
    class="order-info"
  >
    <div class="title">
      订单信息
    </div>
    <div class="refund-state">
      退款成功
    </div>
    <div class="content">
      <div class="item">
        <div class="key">
          退款编号:
        </div>
        <div class="value">
          <template v-if="detail.onlineRefund!=null">
            {{detail.onlineRefund.refundNo}}
          </template>
          <template v-else>
            -
          </template>
        </div>
      </div>

      <div class="item">
        <div class="key">
          订单编号:
        </div>
        <div class="value">
          {{route.query.orderNo}}
        </div>
      </div>

      <div class="item">
        <div class="key">
          操作时间:
        </div>
        <div class="value">
          <template v-if="detail.onlineRefund!=null">
            {{detail.onlineRefund.createdAt}}
          </template>
          <template v-else>
            -
          </template>
        </div>
      </div>

      <div class="item">
        <div class="key">
          下单时间:
        </div>
        <div class="value">
          -
        </div>
      </div>

      <div class="item">
        <div class="key">
          操作人员:
        </div>
        <div class="value">
          <template v-if="detail.onlineRefund!=null">
            <template v-if="detail.onlineRefund.adminId==0">
              <div>
                超级管理员
              </div>
            </template>
          </template>
          <template v-else>
            -
          </template>
        </div>
      </div>

      <div class="item">
        <div class="key">
          收银员:
        </div>
        <div class="value">
          -
        </div>
      </div>

      <div class="item">
        <div class="key vip_key">
          会员余额退款:
        </div>
        <div class="value">
          -
        </div>
      </div>

      <div class="item">
        <div class="key">
          下单门店:
        </div>
        <div
          v-if="detail.orderInfo!=null"
          class="value"
        >
          {{detail.orderInfo.shopName}}
        </div>
      </div>
      <div class="item">
        <el-button @click="handleCommentClick" plain>备注</el-button>
      </div>
    </div>
  </div>

  <template>

  </template>
  <template v-if="detail&&detail.orderInfo!=null">
    <orderCommentDialog
      :oid="route.query.orderNo"
      :userId="detail.orderInfo.userId"
      @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible"
   >
    </orderCommentDialog>
  </template>
  
</template>

<script lang="js">
import { defineComponent, reactive, ref,computed } from 'vue'
import { useRoute } from 'vue-router'
import orderCommentDialog from '../../../detail/base-ui/order-comment-dialog/order-comment-dialog.vue'
export default defineComponent({
  props: {
  detail: {
      type: Object,
      defalult() {
        return {}
      }
    }
  },
  components: {
    orderCommentDialog
  },
  setup(props) {
   const route=useRoute()
   const orderDetail=computed(()=>props.detail)
   const dialogVisible=ref(false)
   const handleCommentClick=()=>{
     dialogVisible.value=true
   }

   const changeDialogVisible=flag=>{
     dialogVisible.value=flag
   }

    return {
      route,
      orderDetail,
      handleCommentClick,
      dialogVisible,
      changeDialogVisible
    }

  }
})
</script>

<style scoped lang="less">
.order-info {
  .refund-state {
    font-family: PingFangSC-Medium Helvetica, Arial, Verdana, Tahoma, sans-serif;
    font-size: 24px;
    color: #111111;
    line-height: 24px;
    padding: 30px 20px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 20px 30px 20px;

    font-size: 14px;

    .item {
      flex: 50%;
      display: flex;
      margin-bottom: 16px;

      .vip_key {
        width: 100px !important;
      }

      .key {
        width: 74px;
      }
    }
  }

  .title {
    position: relative;
    line-height: 48px;
    background-color: rgb(248, 248, 248);
    padding-left: 26px;
  }

  .title::before {
    content: '';
    position: absolute;
    width: 6px;
    height: 20px;
    background-color: #2a82e4;
    left: 12px;
    top: 14px;
  }
}
</style>




