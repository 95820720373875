<template>
  <div class="refund-detail">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="main-content">
      <orderInfo :detail="detail"></orderInfo>
      <clientInfo :detail="detail"></clientInfo>
      <refundDetails :detail="detail">
      </refundDetails>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import orderInfo from './cpns/order-info/order-info.vue'
import clientInfo from './cpns/client-info/client-info.vue'
import refundDetails from './cpns/refund-details/refund-details.vue'
import { useRoute } from 'vue-router'
import { getOrderRefoundDetail } from '@/service/main/order'
import { getOrderPayType } from '@/service/main/reserve'
export default defineComponent({
  props: {

  },
  components: {
    breadcrumb,
    orderInfo,
    clientInfo,
    refundDetails
  },
  setup() {
    const route = useRoute()
    const detail = ref()

    const initPage = async () => {
      const res = await getOrderRefoundDetail(route.query.orderNo)
      const payRes = await getOrderPayType(route.query.orderNo)
      detail.value = res.data
    }
    initPage()

    return {
      breadcrumbList,
      detail
    }

  }
})
</script>

<style scoped lang="less">
.refund-detail {
  min-width: 1080px;
  .main-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;


  }
}
</style>




