<template>
  <div v-if="detail" class="refund-details">
    <div class="title">消费信息</div>
    <div class="detail">
      <div class="titles">
        <div class="goods-name">商品</div>
        <div>可退金额</div>
        <div>数量</div>
        <div>消耗/销售人员</div>
        <div>退款金额</div>
      </div>
      <div class="goods-info">
        <div class="goods-lsit">
          <template v-for="item in detail.orderSku">
            <div class="goods-item">
              <div>{{ item.itemName }}</div>
              <div>¥ {{ ((item.salePrice) / 100).toFixed(2) }}</div>
              <div>x 1</div>
              <div>{{item.empName}}</div>
              <div>¥ {{ ((item.salePrice) / 100).toFixed(2) }}</div>
            </div>
          </template>
        </div>
        <div class="sum">
          <!-- <div class="total">
            <div class="key">订单退款：会员余额:</div>
            <div>0.00</div>
          </div> -->
          <div class="total">
            <div class="key">合计退款:</div>
            <div class="value">
              ¥ {{totalRefund}}
            </div>
          </div>
        </div>

        <div class="btns">
          <div class="btn-wrap">
            <el-button disabled plain>打印小票</el-button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { popup } from '@/utils/pop-up'
import { COMPANY_ID } from '@/common/constant'
import { setOrderNo, setRefund, cancelOrder } from '@/service/main/order'

export default defineComponent({
  props: {
    detail: {
      type: Object,
      defalult() {
        return {}
      }
    }
  },
  components: {

  },
  emits: ['refreshPage'],
  setup(props, { emit }) {
    const router = useRouter()
    const totalRefund=ref(0)
    watch(() => props.detail, (n) => {
      // console.log(n);
      let sum=0
      let refundCash = n.refundCash
      let chuzhiRefund = n.chuzhiRefund
      let cikaRefund = n.cikaRefund
      let onlineRefund = n.onlineRefund
      if(refundCash!=null){
        sum+=refundCash.fee
      }

      if(chuzhiRefund!=null){
          chuzhiRefund.forEach(item=>{
             sum+=item.fee
          })
      }

      if(cikaRefund!=null){
        cikaRefund.forEach(item=>{
          sum+=item.fee
        })
      }

      if(onlineRefund !=null){
        sum+=onlineRefund.amount
      }

      totalRefund.value=(sum/100).toFixed(2)
    }, {
      deep: true
    })

    return {
      COMPANY_ID,
      totalRefund
    }

  }
})
</script>

<style scoped lang="less">
.refund-details {
  .detail {
    padding-left: 36px;
    padding-bottom: 30px;

    .btns {
      padding: 20px;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid rgb(225, 227, 228);

      .btn-wrap {
        width: calc((100% / 8)*2);
        display: flex;
        justify-content: space-evenly;
      }
    }

    .sum {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 20px 0;


      .total {
        width: 300px;
        text-align: left;
        display: flex;
        font-size: 14px;
        margin: 10px 0;

        .key {
          color: rgb(150, 151, 153);
        }

        &>div {
          flex: 1;
        }
      }
    }

    .goods-info {
      border: 1px solid rgb(225, 227, 228);
      border-top: none;

      .goods-lsit {
        .goods-item {
          display: flex;

          &>div {
            flex: 1;
          }
        }
      }

      .goods-item:hover {
        background-color: rgb(246, 244, 255);
      }

      .goods-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        min-height: 70px;
        padding: 0 20px;
        font-size: 14px;
        box-shadow: inset 0 -1px 0 0 #ebedf0;

        &>div {
          flex: 1;
        }

        .goods-name {
          flex: 2;
        }
      }

    }

    .titles {
      margin-top: 30px;
      padding: 0 20px;
      display: flex;
      line-height: 56px;
      background-color: rgb(247, 248, 250);
      font-weight: 500;
      border: 1px solid rgb(225, 227, 228);
      border-bottom: none;



      &>div {
        flex: 1;

      }
    }

  }

  .title {
    position: relative;
    line-height: 48px;
    background-color: rgb(248, 248, 248);
    padding-left: 26px;
  }

  .title::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 20px;
    background-color: #2a82e4;
    left: 12px;
    top: 14px;
  }
}
</style>







