<template>
  <div v-if="detail&&detail.orderInfo" class="client-info">
    <div class="title">客户信息</div>
    <div class="content">
      <div v-if="detail" class="avatar">
        <img
          :src="detail.orderInfo.userAva"
          alt="">
        <div class="name">{{detail.orderInfo.userRealname}}</div>
      </div>

      <div class="item">
        <div class="key">
          客户编号:
        </div>
        <div class="value">
          -
        </div>
      </div>

      <div class="item">
        <div class="key">
          联系电话:
        </div>
        <div class="value">
         {{detail.orderInfo.userMobile}}
        </div>
      </div>

      <div class="item">
        <div class="key">
          客户身份:
        </div>
        <div class="value">
          -
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref,computed } from 'vue'

export default defineComponent({
  props: {
    detail: {
      type: Object,
      defalult() {
        return {}
      }
    }
  },
  components: {

  },
  setup(props) {



    return {

    }

  }
})
</script>

<style scoped lang="less">
.client-info {
  .content {
    padding: 30px 20px;
    font-size: 14px;

    .item {
      display: flex;
      margin: 10px 0;

      .key {
        width: 76px;

      }
    }

    .avatar {
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }


  .title {
    position: relative;
    line-height: 48px;
    background-color: rgb(248, 248, 248);
    padding-left: 26px;
  }

  .title::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 20px;
    background-color: #2a82e4;
    left: 12px;
    top: 14px;
  }
}
</style>




