export const breadcrumbList = [
  {
    name: '订单管理',
    path: ''
  },
  {
    name: '退款列表',
    path: ''
  },
  {
    name: '退款详情',
    path: ''
  }
]
